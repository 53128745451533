import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 6; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/11-dec-23-3/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function WorldDisabilityDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">World Disability Day Assembly</h1>

        <p>
          Chinmaya Vidyalaya and Tamana Special School collaborated to celebrate
          the International Day of Persons with Disabilities.
          <br />
          <br />
          The joint celebrations kicked off with an inclusion walk carrying
          messages highlighting positivity and acceptance for everyone.
          <br />
          <br />
          A brilliant Nukkad Natak shared the powerful yet vital message of
          empathy over sympathy towards people with disabilities. It was
          followed by a soulful song and an enthralling dance performance.
          <br />
          <br />
          Senior Headmistress expressed gratitude to Tamana School, on behalf of
          the Vidyalaya, for the collaborative celebration.
          <br />
          <br />
          The Vidyalaya believes in fostering a culture of support and
          sensitivity and the universal right to a life with dignity.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
